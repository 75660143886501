<template>
  <div class="block-header divider-warp">
    <div style="font-size: 15px;  color: #000;display:flex;align-items:end">
      {{ title }}
      <span class="tag">{{ tag }}</span>
    </div>

    <div>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      required: false
    }
  }
};
</script>
<style>
.el-form .block-header {
  width: 100%;
}
</style>
<style scoped>
.block-header {
  margin: 10px 0px;
  font-weight: 400;
  color: #1f2f3d;
  background: #ecf8ff;
  /* border: 1px solid #ecf8ff; */
  border-left: 5px solid #50bfff;
  padding: 10px 5px;
  /* border-radius: 4px; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #ecf5ff;
  border-left: 5px solid #409eff;
}

.divider-horizontal {
  display: block;
  height: 1px;
  width: 100%;
  /* margin: 24px 0; */
  margin: 36px 0 24px 0;
  background-color: #dcdfe6;
  position: relative;
}

.divider-title {
  left: 20px;
  transform: translateY(-50%);
  position: absolute;
  background-color: #fff;
  padding: 0 20px;
  color: #303133;
  font-weight: 500;
  font-size: 14px;
}

.divider-operate {
  right: 20px;
  transform: translateY(-50%);
  position: absolute;
  background-color: #fff;
  padding: 0 20px;
  color: #303133;
  font-weight: 500;
  font-size: 14px;
}

.tag {
  font-size: 13px;
  color: #999;
  margin-left: 10px;
}
</style>
